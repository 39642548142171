import { Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { NavItem } from '@app/admin/models';
import { UserSwitchService } from '@app/modules/user-switch';

@Component({
    selector: 'app-menu-mobile',
    templateUrl: './menu-mobile.component.html',
    styleUrls: ['./menu-mobile.component.scss'],
})
export class MenuMobileComponent {
    private router = inject(Router);
    private readonly userSwitchService = inject(UserSwitchService);

    @Input({ required: true }) menu!: NavItem[];

    userSwitchEnabled$ = this.userSwitchService.enabled$;

    onLogoutClick(event: MouseEvent) {
        event.preventDefault();
        this.router.navigate(['/logout'], {
            skipLocationChange: true,
        });
    }

    onUserSwitchClick(event: MouseEvent) {
        event.preventDefault();
        this.userSwitchService.reset();
        this.router.navigate(['/customers']);
    }

    onProfileClick(event: MouseEvent) {
        event.preventDefault();
        this.router.navigate(['/user-profile']);
    }
}
