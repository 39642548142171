<div class="header-container">
    <header class="mx-5 | 3xl:container 3xl:mx-auto">
        <div>
            <app-logo />
        </div>

        <div *ngIf="xl$ | async" class="menu">
            <app-menu [menu]="(menuItems$ | async) ?? []" />
        </div>

        <div class="ml-auto border-padding-box" *ngIf="md$ | async">
            <app-language-switch />
        </div>

        <div *ngIf="md$ | async" class="border-padding-box">
            <app-profile type="user" />
        </div>
        <div *ngIf="lg$ | async" class="border-padding-box">
            <app-switch-user *ngIf="(userSwitchEnabled$ | async) === true" />
            <app-logout *ngIf="(userSwitchEnabled$ | async) === false" />
        </div>

        <div [ngClass]="{ 'ml-auto': (md$ | async) === false }" class="border-padding-box" *ngIf="(xl$ | async) === false">
            <app-mobile-menu-button>
                <app-menu-mobile [menu]="(menuItems$ | async) ?? []" />

                <div class="bottom-10 left-10 absolute">
                    <app-language-switch />
                </div>
            </app-mobile-menu-button>
        </div>
    </header>
</div>
